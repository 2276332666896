import { useNavigate } from "react-router-dom"
import { useStore } from "../hooks/store"
import { useState, useEffect } from "react"
import { isValidEmail } from "../utils/helpers"
import { sendMessage } from "../utils/sendEmail"
import light from "../assets/imgs/light.png"
import { useIdleTimer } from "react-idle-timer"
import HDBack from "../assets/imgs/HDBack.png"

const ThankYou2 = () => {
  const navigate = useNavigate()
  const userFormData = useStore((state) => state.userFormData)
  const [tableData, setTableData] = useState<{name: string, score: string}[]>([])

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_IDLE_TIMEOUT),
    onIdle: () => {
      navigate("/")
    },
  })

  useEffect(() => {
    // if (isValidEmail(userFormData.email) && userFormData.messageMe) {
    //   sendMessage(userFormData.email, "Thank you", "Thanks for using our app", "Thanks for using our app")
    // }

    const scoreList = JSON.parse(localStorage.getItem("scoreList") as string);
    if(scoreList && scoreList.length > 0) {
      setTableData(scoreList)
    }

  }, [userFormData])

  const goNext = () => {
    navigate("/")
  }

  return (
    <div>
      <div className="absolute justify-center items-center ml-56 rotate">
        <img src={light}></img>
      </div>
      <div className='scorePane grid grid-cols-3 gap-4 sticky z-50'>
        <div className='col-span-3 mt-96 mx-12 rounded-3xl h-[1305px] overflow-hidden'>
          <div className='relative rounded-3xl overflow-x-auto pt-8 px-12'>
            <table className='w-full text-5xl text-left text-white dark:white'>
              {/* <thead className='text-4xl text-white uppercase'>
                <tr className='border-b'>
                  <th scope='col' className='px-6 py-5'>
                    No
                  </th>
                  <th scope='col' className='px-6 py-5'>
                    Name
                  </th>
                  <th scope='col' className='px-6 py-5 text-right'>
                    Score
                  </th>
                </tr>
              </thead> */}
              <tbody>
                {tableData.slice(0,10).map((row, index) => (
                  <tr className='' key={index}>
                    <td className='px-6 py-4 text-center text-order'>{index+1}</td>
                    <td className='px-6 py-4 text-center text-score'>{(row.score === "" || row.score === undefined || row.score === null) ? "0" : row.score}</td>
                    <td className='px-6 py-4 text-center text-name'>{row?.name}</td>
                  </tr>
                ))}
                {tableData.length == 0 && <tr><td colSpan={3} className="text-3xl text-center text-white pt-4">No data found</td></tr>}
              </tbody>
            </table>
          </div>
          <div className="col-span-3">
            <div className="m-auto">
              <button style={{top:'1200px'}}
                onClick={goNext}
                type='button'
                className='btn-close w-full'
              >
              </button>
            </div>
          </div>
        </div>

        <div className='bottomlogo'>
    
      
      
    <img src={HDBack} ></img>
</div>
      </div>
    </div>
  )
}

export default ThankYou2
