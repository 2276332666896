// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

// config
const firebaseConfig = {
  apiKey: "AIzaSyCxtpeYlJFlvp1lwcKd2AE4MxE0T7GlQ2I",
  authDomain: "subwayfruitnunja.firebaseapp.com",
  projectId: "subwayfruitnunja",
  storageBucket: "subwayfruitnunja.appspot.com",
  messagingSenderId: "323892313563",
  appId: "1:323892313563:web:2afc0331ffe58208927bfe"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

export { db, auth }
