import { Link, useParams } from "react-router-dom"
import HDBack from "../../assets/imgs/HDBack.png"

const Header = () => {
  return (
    <div className='Check' >
      {/* <h1 className='text-7xl text-center'>BRANDING</h1> */}
      
      
      <img src={HDBack}></img>
    </div>
  )
}

export default Header
