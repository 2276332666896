import { useNavigate } from "react-router-dom"
import QRCode from "react-qr-code"
import GamePlayVideo from "../assets/videos/portrait.mp4"

const Home = () => {
  const navigate = useNavigate()

  const goNext = () => {
    navigate("/instruction")
  }

  return (
    <div>
      <div className='grid grid-cols-3 gap-4'>
      <div className='bg-[#849483] col-span-3 rounded-3xl h-[1570px] Check' onClick={goNext}>
          <video autoPlay loop muted className='object-cover z-[-1] w-full h-full rounded-3xl border-8'>
            <source src={GamePlayVideo} type='video/mp4' />
          </video>
        </div>
      </div>
    </div>
  )
}

export default Home
