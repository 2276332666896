import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import SponserVideo from "../assets/videos/portrait.mp4"
import { Button } from "flowbite-react"
import { useIdleTimer } from "react-idle-timer"

const Sponser = () => {
  const navigate = useNavigate()
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false)

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_IDLE_TIMEOUT),
    onIdle: () => {
      navigate("/")
    },
  })

  useEffect(() => {
    setTimeout(() => {
      setShowSkipButton(true)
    }, 5000)
  }, [])

  const goNext = () => {
    navigate("/game")
  }

  const playingVideoEnded = () => {
    navigate("/game")
  }

  return (
    <div>
      <div className='grid grid-cols-3 gap-4'>
        <div className='bg-[#849483] col-span-3 mt-4 rounded-3xl h-[1570px]'>
          <video onEnded={playingVideoEnded} autoPlay muted className='object-cover z-[-1] w-full h-full rounded-3xl'>
            <source src={SponserVideo} type='video/mp4' />
          </video>
          {
            showSkipButton && 
            <Button color='success' size='xl' className='absolute bottom-[70px] right-[70px]' onClick={goNext}>
              <span className='text-4xl'>Skip</span>
            </Button>
          }
        </div>
      </div>
    </div>
  )
}

export default Sponser
