import { useNavigate } from "react-router-dom"
import GamePlayVideo from "../assets/videos/middle.mp4"
import IntImg from "../assets/imgs/Instructions.png"
import light from "../assets/imgs/light.png"
import { useIdleTimer } from "react-idle-timer"

const Instruction = () => {
  const navigate = useNavigate()

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_IDLE_TIMEOUT),
    onIdle: () => {
      navigate("/")
    },
  })

  const goNext = () => {
    navigate("/game")
  }

  return (
    <div onClick={goNext}>
      <div className='grid scale-110 mt-10'>
        <div className="z-50">
          <img src={IntImg}></img>
        </div>
        <div className="absolute justify-center items-center ml-56 rotate">
          <img src={light}></img>
        </div>
        <a href="#" className="nextBTN z-50"></a>
      </div>
    </div>
  )
}

export default Instruction
