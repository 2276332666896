import create from "zustand"

type State = {
  userFormData: {firstName: string, lastName: string, mobile: string, email: string}
  currentScore: any
  setUserFormData: (data: any) => void
  setCurrentScore: (data: any) => void
}

export const useStore = create<State>((set) => ({
  userFormData: {firstName: "", lastName: "", mobile: "", email: ""},
  currentScore: null,
  setUserFormData: (data) => set(() => ({ userFormData: data })),
  setCurrentScore: (data) => set(() => ({ currentScore: data })),
}))
