import { useNavigate } from "react-router-dom"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import { useStore } from "../hooks/store"
import light from "../assets/imgs/light.png"
import HDBack from "../assets/imgs/HDBack.png"

import { useState } from "react"
import ReactModal from "react-modal"
import { Textarea } from "flowbite-react"
import {
  collection,
  addDoc,
  Timestamp,
} from "firebase/firestore"
import { db } from "../hooks/firebase"
import { useIdleTimer } from "react-idle-timer"

type FormData = {
  firstName: string
  lastName: string
  email: string
  mobile: string
}

const CollectData = () => {
  const navigate = useNavigate()

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_IDLE_TIMEOUT),
    onIdle: () => {
      navigate("/")
    },
  })

  const setUserFormData = useStore((state) => state.setUserFormData)
  const currentScore = useStore((state) => state.currentScore)

  const [modalStatus, setModalStatus] = useState<boolean>(false);

  const [keyboardIns, setKeyboardIns] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [keyLayoutName, setKeyLayoutName] = useState<string>("default")
  const [focusedInput, setFocusedInput] = useState<string>("")
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  })

  const goNext = () => {
    navigate("/thank-you")
  }

  const onKeyboardInit = (keyboard: any) => {
    setKeyboardIns(keyboard)
  }

  const setValueToInput = (value: any) => {
    const updated: FormData = { ...formData }

    switch (focusedInput) {
      case "firstName":
        updated.firstName = value
        break
      case "lastName":
        updated.lastName = value
        break
      case "email":
        updated.email = value
        break
      case "mobile":
        updated.mobile = value
        break

      default:
        break
    }

    setFormData((prevState) => ({
      ...prevState,
      ...updated,
    }))

    keyboardIns.setInput(value)
  }

  const onChange = (value: any) => {
    setValueToInput(value)
  }

  const onKeyPress = (button: any) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift()
    } else if (button === "{numbers}" || button === "{abc}") {
      handleNumbers()
    }

    let currentInputValue = ""
    switch (focusedInput) {
      case "firstName":
        currentInputValue = formData.firstName
        break
      case "lastName":
        currentInputValue = formData.lastName
        break
      case "email":
        currentInputValue = formData.email
        break
      case "mobile":
        currentInputValue = formData.mobile
        break

      default:
        break
    }

    let specialValue = null
    switch (button) {
      case "{@}":
        specialValue = currentInputValue + "@"
        break
      case "{@@}":
        specialValue = currentInputValue + "@"
        break
      case "{.}":
        specialValue = currentInputValue + "."
        break
      case "{-}":
        specialValue = currentInputValue + "-"
        break
      case "{_}":
        specialValue = currentInputValue + "_"
        break
      case "{.com}":
        specialValue = currentInputValue + ".com"
        break
      case "{#}":
        specialValue = currentInputValue + "#"
        break
      case "{+}":
        specialValue = currentInputValue + "+"
        break

      default:
        break
    }

    if (specialValue) {
      // keyboardIns.setInput(specialValue)
      setValueToInput(specialValue)
    }
  }

  const handleShift = () => {
    const currentLayout = keyLayoutName
    const shiftToggle = currentLayout === "default" ? "shift" : "default"

    setKeyLayoutName(shiftToggle)
  }

  const handleNumbers = () => {
    const currentLayout = keyLayoutName
    const numbersToggle = currentLayout !== "numbers" ? "numbers" : "default"

    setKeyLayoutName(numbersToggle)
  }

  const onInputFocus = (inputName: string, inputValue: string) => {
    keyboardIns.setInput(inputValue)
    setFocusedInput(inputName)
  }

  const onMessageMeChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      ...{ messageMe: e.target.checked },
    }))
  }

  const isEmail = (search: string) => {
    const expression: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

    const email: string = search;
    const result: boolean = expression.test(email);
    return result;
  }

  const onSubmit = () => {
    if (formData.firstName && formData.firstName.length >= 2 && formData.lastName && formData.lastName.length >= 2 && formData.email && isEmail(formData.email)) {
      setUserFormData(formData)

      const scoreList = JSON.parse(localStorage.getItem("scoreList") as string);
      const score = currentScore;
      const name = formData.firstName.substr(0, 2).toUpperCase() + formData.lastName.substr(0, 1).toUpperCase();
      if (scoreList === null || scoreList === undefined || scoreList.length === 0) {
        localStorage.setItem("scoreList", JSON.stringify([{ name: name, score: score }]));
      } else {
        let bupdateflag = false;
        for (let i = 0; i < scoreList.length; i++) {
          if (scoreList[i].name === name) {
            if (scoreList[i].score < score)
              scoreList[i].score = score;
            bupdateflag = true;
          }
        }
        if (bupdateflag === false) {
          scoreList.push({ name: name, score: score });
        }
        scoreList.sort(function (a: any, b: any) {
          return b.score - a.score;
        });
        const scores = scoreList.slice(0,10)
        localStorage.setItem("scoreList", JSON.stringify(scores));
      }

      addDoc(collection(db, "score_results"), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        score: currentScore,
        kioskId: process.env.REACT_APP_KIOSK_ID,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      }).then(docRef => {
        console.log('firebase success')
      })
        .catch(console.error)
        .finally(
        // () => goNext()
      )

      goNext()
    }
  }

  const onShowTerms = () => {
    setModalStatus(true);
  }

  const onCloseModal = () => {
    setModalStatus(false);
  }

  // const focusedStyle = { outline: "1px solid #2196f3", boxShadow: "0px 0px 7px #2196f3" }
  console.log(modalStatus, "hello")
  return (
    <div className="relative" >
      <div className="absolute justify-center items-center ml-156 rotate z-10">
        <img src={light}></img>
      </div>
      <div className='dataPane grid grid-cols-3 gap-4 sticky z-20'>
        <div className='col-span-3 mt-96 rounded-3xl h-[1310px]'>
        <form className='flex flex-col gap-4 Checkmx-12 mt-[-245px]'>
            <div className='grid grid-cols-2 gap-4'>
              <div>
                <input style={{color:'white' /* ,position: "absolute", top: 50 */}}
                  value={formData.firstName}
                  readOnly
                  onFocus={(e) => onInputFocus("firstName", e.target.value)}
                  type='text'
                  placeholder=''
                  className='InputBody bg-transparent border-0 text-gray-900 text-5xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-8 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  autoComplete='off'
                  required
                />
                <p className="p_text text-4xl ml-5 text-white">FIRST NAME</p>
              </div>
              <div>
                <input style={{color:'white'}}
                  value={formData.lastName}
                  readOnly
                  onFocus={(e) => onInputFocus("lastName", e.target.value)}
                  type='text'
                  placeholder=''
                  className='InputBody bg-transparent border-0 text-gray-900 text-5xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-8 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  autoComplete='off'
                  required
                />
                <p className="p_text text-4xl ml-5 text-white">LAST NAME</p>
              </div>
    
              
              {/* <div>
                <input
                  value={formData.mobile}
                  readOnly
                  onFocus={(e) => onInputFocus("mobile", e.target.value)}
                  type='text'
                  placeholder=''
                  className='InputBody bg-transparent border text-gray-900 text-5xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-8 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  autoComplete='off'
                  required
                />
              </div> */}
              <div className="col-span-2">
                <input style={{color:'white'}}
                  value={formData.email}
                  readOnly
                  onFocus={(e) => onInputFocus("email", e.target.value)}
                  type='email'
                  placeholder=''
                  className='InputBodyL bg-transparent border-0 text-gray-900 text-5xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-8 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  autoComplete='off'
                  required
                />
                <p className="p_text text-4xl ml-5 text-white">EMAIL</p>
              </div>
              <div className='col-span-2 flex items-center mt-4 mb-8 mx-auto'>
                <div className="m-auto ml-8">
                  <button
                    onClick={onShowTerms}
                    type='button'
                    className='btn-term w-full'
                  >
                  </button>
                </div>
                <ReactModal isOpen={modalStatus} ariaHideApp={false} className="modal-term m-auto absolute">
                  {/* <Textarea readOnly className="term-content m-auto fixed border-none">
                  </Textarea> }
                  <h1 style={{ fontSize: "70px", position: 'absolute', zIndex: 1, top: '50px', color: '#1d9142', fontWeight: 'bold' }}>Contest Rules & Regulations</h1>
                  <div className="term-content m-auto fixed border-none">
                    <b style={{ fontSize: "37px" }}></b> To participate, you must be a legal resident of Ontario, Canada and 18 years
                    of age or older at the time of entry. Open at 5:00 PM EST on 02.28.23 and ends at the beginning of the
                    fourth (4th) quarter of the Toronto Raptors game on 02.28.23. For entry: play the Subway FRESH-
                    TACULAR e-game at ScotiaBank Arena on February 28, during the Toronto Raptors game. Multiple
                    entries permitted. Participants must answer a mathematical question. Winners will be randomly selected
                    and will be contacted on or around:  03.13.23. Odds of winning prizes depend on number of eligible
                    entries. 10 Prizes available:  Grand Prize (1):  Pair Tickets to April 9 Toronto Raptors home game (ARV:
                    $1,500) .  Second prize (4):  Pair tickets to a future Toronto Raptors home game (ARV:  $325).  Third
                    prize (5):   Official Toronto Raptors Jersey (ARV: $170). Winners must comply with the terms and
                    conditions of the use of Toronto Raptor Tickets and Jerseys, as applicable. Sponsor:   Franchisee
                    Canadian Advertising Trust. The NBA and individual NBA member team identifications are trademarks
                    and copyrighted designs, and/or other forms of intellectual property of NBA. Properties, Inc. and the
                    respective NBA member teams.  © 2023 NBA Properties, Inc.  All rights reserved.
                    </div>*/}
                  <button
                    onClick={onCloseModal}
                    type='button'
                    className='btn-term-close w-full'
                  >
                  </button>
                </ReactModal>
              </div>
            </div>
          </form>

          <div className='keyboard-wrapper m-auto w-full left-0 Checkpx-12 pb-5 bottom-4 mt-[-30px]'>
            <Keyboard
              layoutName={keyLayoutName}
              onChange={onChange}
              disableButtonHold={true}
              onKeyPress={onKeyPress}
              onInit={onKeyboardInit}
              stopMouseUpPropagation={true}
              stopMouseDownPropagation={true}
              layout={{
                default: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
                  "{em} q w e r t y u i o p [ ] \\",
                  "{eem} a s d f g h j k l ; ' {ent}",
                  "{shift} z x c v b n m , . / {shift}",
                  "{.com} {_} {@@}",
                ],
                shift: [
                  "~ ! @ # $ % ^ & * ( ) _ + {backspace}",
                  "{em} Q W E R T Y U I O P { } |",
                  "{eem} A S D F G H J K L : \" {ent}",
                  "{shift} Z X C V B N M < > ? {shift}",
                  "{.com} {_} {@@}",
                ],
                numbers: ["1 2 3", "4 5 6", "7 8 9", "{abc} 0 {backspace}"],
              }}
              display={{
                "{numbers}": "123",
                "{ent}": "return",
                "{escape}": "esc ⎋",
                "{tab}": "tab ⇥",
                // "{backspace}": "⌫",
                "{backspace}": "delete",
                "{capslock}": "caps lock ⇪",
                // "{shift}": "⇧",
                "{shift}": "shift",
                "{controlleft}": "ctrl ⌃",
                "{controlright}": "ctrl ⌃",
                "{altleft}": "alt ⌥",
                "{altright}": "alt ⌥",
                "{metaleft}": "cmd ⌘",
                "{metaright}": "cmd ⌘",
                "{abc}": "ABC",
                "{@}": "@",
                "{@@}": "@",
                "{-}": "-",
                "{_}": " ",
                "{.}": ".",
                "{.com}": ".com",
                "{#}": "#",
                "{+}": "+",
                "{=}": "=",
                "{☺}": "☺",
                "{space}": "space",
                "{return}": "return",
                "{em}": " ",
                "{eem}": " ",
              }}
            />
          </div>
          <div className='flex justify-center gap-4 mt-3'>
            <button
              onClick={onSubmit}
              type='button'
              className='btn-submit w-full'
            >
            </button>
            <button
              onClick={goNext}
              type='button'
              className='btn-skip w-full'
            >
            </button>
          </div>


          <div className='bottomlogo'>
    
      
      
    <img src={HDBack} ></img>
</div>


        </div>

        


      </div>

      

    </div>
    
    
    
  )
}

export default CollectData
