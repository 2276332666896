import React, {useState, useEffect} from "react"
 import Header from "./partials/Header"
import Footer from "./partials/Footer"
import BgImgGreen from "../assets/imgs/BG_Green.png"
import BgImg from "../assets/imgs/BG.png"


interface MasterLayoutProps {
  children?: React.ReactNode
  bg?: string
}

const MasterLayout = ({ children, bg }: MasterLayoutProps) => {


  if (window.location.pathname !== "/collect-data" &&  window.location.pathname !== "/instruction" &&  window.location.pathname !== "/thank-you")
  {return (
    <div
      className='all-content overflow-hidden w-[1080px] h-[1920px] p-12'
      style={{ backgroundImage: "url(" + bg + ")" }}
    >
      <Header />
      <div className='body-container w-full h-full'>{children}</div>
      <Footer />
    </div>
  )}
  else
  {return (
    <div
      className='all-content overflow-hidden w-[1080px] h-[1920px] p-12'
      style={{ backgroundImage: "url(" + bg + ")" }}
    >
      
      <div className='body-container w-full h-full'>{children}</div>
      <Footer />
    </div>
  )}
}

export default MasterLayout
