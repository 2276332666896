import { useEffect, useState } from "react"
import { Navigate, Route, Routes, useParams, useNavigate, useLocation } from "react-router-dom"
import MasterLayout from "./layout/Master"

import Home from "./views/Home"
import Home2 from "./views/Home2"
import Home3 from "./views/Home3"
import Home4 from "./views/Home4"

import ThankYou from "./views/ThankYou"
import ThankYou2 from "./views/ThankYou2"

import Instruction from "./views/Instruction"
import Sponser from "./views/Sponser"
import Game from "./views/Game"
import CollectData from "./views/CollectData"

import BgImgGreen from "./assets/imgs/BG_Green.png"
import BgImg from "./assets/imgs/BG.png"

const App = () => {

  const [background, setBackground] = useState<any>(BgImgGreen)

  const location = useLocation();
  useEffect(() => {
    if(location.pathname === "/"){
      setBackground(BgImgGreen);
    }else{
      setBackground(BgImg);
    }
  }, [location.pathname]);

  return (
    <MasterLayout bg={background}>
      <Routes>
        <Route path='/' element={<Home4 />} />
        <Route path='/instruction' element={<Instruction />} />
        <Route path='/sponser' element={<Sponser />} />
        <Route path='/game' element={<Game />} />
        <Route path='/collect-data' element={<CollectData />} />
        <Route path='/thank-you' element={<ThankYou2 />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </MasterLayout>
  )
}

export default App
