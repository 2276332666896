import { Link, useNavigate, useLocation, useParams } from "react-router-dom"
import BackArrowImg from "../../assets/imgs/back-arrow.png"
import { useStore } from "../../hooks/store"

const Footer = () => {
  const navigate = useNavigate()
  const location = useLocation()

  

  return <div></div>
}

export default Footer
