import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useStore } from "../hooks/store"

const Game = () => {
  const navigate = useNavigate()
  const setCurrentScore = useStore((state) => state.setCurrentScore)

  useEffect(() => {
    window.addEventListener('message', function (e) {
      // Get the sent data
      const data = e.data;
      if((typeof data === 'string' || data instanceof String) && data.indexOf('ninja_game_ended') > -1) {
        const decoded = JSON.parse(data.toString());
        setCurrentScore(decoded?.score)
        goNext()
      }
    });
  }, [])

  const goNext = () => {
    navigate("/collect-data")
  }

  return (
    <div className="fixed w-full h-full top-0 left-0">
      <iframe src="./subway_fruit_ninja_game/index.html" style={{ width: "100%", height: "100%", marginLeft: "0px" }} allowFullScreen={false}></iframe>
    </div>
  )
}

export default Game
